import React from 'react';
import '../index.css';
import YavrioIcon from "../images/YavrioIcon";
import {Link} from "react-router-dom";

const NotFoundPage = () => {

    return (
        <div className="flex flex-col justify-center items-center h-screen w-full">
            <YavrioIcon/>
            <h1>404 payment not found</h1>
            <p>Contact <Link to='mailto:support@yavr.io' className='text-yavrio-blue underline'>Yavrio support</Link> for assistance.</p>
        </div>
    );
};

export default NotFoundPage;