import '../index.css';
import {usePayment} from "../context/paymentContext"; // Import a separate CSS file for styling

const PaymentScheme = (props) => {

    const { setPaymentScheme } = usePayment();

    const title = props.title;
    const details = props.details;
    const notes = props.notes;
    const scheme = props.paymentScheme;
    const enabled = props.enabled;

    return (
        <div className={"yvr-box yvr-box-clickable yvr-centered !px-14 !py-12 cursor-pointer" + (enabled ? "" : " disabled")}
             onClick={enabled ? () => setPaymentScheme(scheme) : null}>
            <h1 className="mb-8">{title}</h1>
            <div className="mt-auto mb-6">
                {details.map((detail) => (
                    <p className="mb-4">{detail}</p>
                ))}
            </div>
            <div className="mt-auto">
                {notes.map((note) => (
                    <p className="mb-4 text-xs">{note}</p>
                ))}
            </div>
        </div>
    );
};

export default PaymentScheme;