import React, { useState } from 'react';
import '../../index.css';
import BACSAccountScreen from './BACSAccountScreen';
import BACSAuthScreen from './BACSAuthScreen';
import BACSSuccessScreen from './BACSSuccessScreen';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {useAuth} from "../../context/authContext";

const BACSAuth = (props) => {

    const { authenticated } = useAuth();

    // Validation
    const [inputValues, setInputValues] = useState({
        accountName: '',
        accountNo: '',
        sortCode: '',
    });

    // State to hold validation errors
    const [validationErrors, setValidationErrors] = useState({
        accountName: 'Missing',
        accountNo: 'Missing',
        sortCode: 'Missing',
    });

    const [currentScreen, setCurrentScreen] = useState(0);
    const totalScreens = 3; // Set the total number of screens

    const handleCheck = () => {
        setCurrentScreen((prevScreen) => (prevScreen + 1) % totalScreens);
    };

    const handleBack = () => {
        setCurrentScreen((prevScreen) => (prevScreen - 1 + totalScreens) % totalScreens);
    };

    return (

        <div className="yvr-box payment-auth">
            <ToastContainer />

            {currentScreen === 0 && <BACSAuthScreen
                inputValues={inputValues}
                setCurrentScreen={setCurrentScreen}
            />}

            {currentScreen === 1 && <BACSAccountScreen
                inputValues={inputValues}
                setInputValues={setInputValues}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
            />}

            {currentScreen === 2 && <BACSSuccessScreen />}

            <div className='button-group'>

                { currentScreen === 0 &&
                    <>
                        <button className='back-button' onClick={() => props.setPaymentScheme('')}>
                            Back
                        </button>
                        <button className='next-button' disabled={!authenticated} onClick={handleCheck}>
                            Next
                        </button>
                    </>
                }

                {currentScreen === 1 &&
                    <>
                        <button className='back-button' onClick={handleBack}>
                            Back
                        </button>
                        <button className='next-button' onClick={handleCheck}>
                            Send for Authorisation
                        </button>
                    </>
                }
                { currentScreen === 2 &&
                    <>
                        <button className='back-button' onClick={handleBack}>
                            Back
                        </button>
                    </>
                }
            </div>

        </div>
    );
};

export default BACSAuth;