import React, {useEffect} from 'react';
import '../index.css';
import PaymentScheme from "./PaymentScheme";
import {useAuth} from "../context/authContext";

const SelectPaymentType = () => {

    const { setAuthenticated } = useAuth();

    useEffect(() => {
        setAuthenticated(false);
    }, []);

    return (
        <div className="text-center">
            <h3>Select your payment method</h3>
            <div className="grid grid-cols-2 gap-6 max-w-3xl mt-12 mb-16">
                <PaymentScheme
                    title="Faster Payments (FPS)"
                    paymentScheme="OB"
                    enabled={true}
                    details={[
                        "Real-time payments",
                        "Up to £1m per transaction*",
                    ]}
                    notes={[
                        "* Your bank may impose a lower limit",
                    ]}
                />
                <PaymentScheme
                    title="BACS"
                    paymentScheme="BACS"
                    enabled={false}
                    details={[
                        "3-day settlement",
                        "Up to £20m per transaction",
                    ]}
                    notes={[

                    ]}
                />
            </div>
        </div>
    );
};

export default SelectPaymentType;