import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='yavrio-mark'
      version='1.1'
      viewBox='0 0 360.893 281.2'
    >
      <g transform='matrix(1.33333 0 0 -1.33333 0 281.2)'>
        <g
          fillOpacity='1'
          fillRule='nonzero'
          stroke='none'
          transform='scale(.1)'
        >
          <path
            fill='#219d5d'
            d='M456.734 2108.99L0 1415.4 900.523.57l451.367 705.328L456.734 2108.99'
          ></path>
          <path
            fill='#24b269'
            d='M1354.89 1406.96l448.76 700.97H906.352z'
          ></path>
          <path
            fill='#24b269'
            d='M1803.37 1411.42l-451.48-705.522L900.566.57l.329-.57h902.235l.24.57 903.3 1406.36-448.34 702.06-454.96-697.57'
          ></path>
          <path fill='#c6c5c5' d='M900.41.57l.031.13.028-.04-.059-.09'></path>
          <path
            fill='#197947'
            d='M900.469.66l-.028.04 235.699 1043.23 215.61-338.032L900.469.66'
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;