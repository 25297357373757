import React from 'react';
import '../index.css'; // Import a separate CSS file for styling
import { useSearchParams} from 'react-router-dom';
import {useAuth} from "../context/authContext";
import {usePayment} from "../context/paymentContext";

const PaymentDetails = () => {

  // URL param
  const [searchParams] = useSearchParams();

  const { authenticated } = useAuth();
  const { paymentDetails } = usePayment();

  return (
    <div>
        <div className="yvr-box payment-details">
            <h3>Payment Details</h3>
            { authenticated ?
                <div>
                    <p>Amount: <span className="font-bold text-gray-800">{paymentDetails.amount}</span></p>
                    <p>Currency: <span className="font-bold text-gray-800">{paymentDetails.currency}</span></p>
                    <p>Payee: <span className="font-bold text-gray-800">{paymentDetails.payee}</span></p>
                    <p>Reference: <span className="font-bold text-gray-800">{paymentDetails.reference}</span></p>
                    <p>Status: <span className="font-bold text-gray-800">{paymentDetails.status}</span></p>
                </div>
                :
                <div className="blur select-none">
                    <p>Amount: <span className="font-bold text-gray-800">£0000.00</span></p>
                    <p>Currency: <span className="font-bold text-gray-800">{paymentDetails.currency}</span></p>
                    <p>Payee: <span className="font-bold text-gray-800">Lorem Ipsum</span></p>
                    <p>Reference: <span className="font-bold text-gray-800">Lorem ipsum dolor sit amet</span></p>
                    <p>Status: <span className="font-bold text-gray-800">Payment Status</span></p>
                </div>
            }
        </div>
        <div className='mt-10 ml-2'>
            <p className='text-gray-300 text-xs'>Payment ID: {searchParams.get('paymentId')}</p>
        </div>
    </div>
  );
};

export default PaymentDetails;