import React from 'react';
import '../../index.css';
import microsoft from '../../images/microsoft-icon.svg';
import google from '../../images/google-icon.svg';
import { msalInstance, loginRequest } from "../../config/msal.config";
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from "../../context/authContext";

const OAuthLoginButtons = (props) => {

    const { setAuthenticated, setUser } = useAuth();

    const microsoftLogin = async () => {
        try {
            const loginResponse = await msalInstance.loginPopup(loginRequest);
            setUser(loginResponse.account.userName);
            setAuthenticated(true);
            props.setCurrentScreen((prevScreen) => (prevScreen + 1));

            // Use the acquired token to call your API or perform other actions
        } catch (error) {

        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            setUser(tokenResponse.profileObj.email);
            setAuthenticated(true);
            props.setCurrentScreen((prevScreen) => (prevScreen + 1));
        },
        onError: error => {},
    });

    return (
        <>
            <div className="oauth-buttons text-center grid grid-cols-1 gap-2">
                <button
                    onClick={microsoftLogin}
                    className="bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-none font-medium text-gray-700 py-4 flex flex-row">
                    <img src={microsoft} alt="" className="mr-3"/>
                    <span className="w-48 text-center">Sign in with Microsoft</span>
                </button>
                {/*<button*/}
                {/*    onClick={() => googleLogin()}*/}
                {/*    className="bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-none font-medium text-gray-700 py-4 flex flex-row">*/}
                {/*    <img src={google} alt="" className="mr-3"/>*/}
                {/*    <span className="w-48 text-center">Sign in with Google</span>*/}
                {/*</button>*/}
            </div>
        </>
    );
};

export default OAuthLoginButtons;