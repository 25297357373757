import React from 'react';
import '../index.css'; // Import a separate CSS file for styling
import Icon from '../images/YavrioIcon';
import Header from "../components/Header";

const AuthSuccessPage = () => {
  return (
      <>
        <div className="App">


          <header className="App-header">
            <Header/>
          </header>

          <div className='page-wrapper'>
            <h2>Authentication successful</h2>
            <Icon/>
          </div>

        </div>
      </>
  );
};

export default AuthSuccessPage;