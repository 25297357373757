import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext(null);

export const PaymentProvider = ({ children }) => {

    const [paymentId, setPaymentId] = useState('');

    const [paymentDetails, setPaymentDetails] = useState({
        amount: null,
        currency: null,
        payee: null,
        reference: null,
        status: null
    });

    const [paymentScheme, setPaymentScheme] = useState('');

    const [inputValues, setInputValues] = useState({
        accountName: '',
        accountNo: '',
        sortCode: '',
        bic: '',
        iban: '',
        country: 'GB',
    });

    return (
        <PaymentContext.Provider value={{
            paymentDetails,
            setPaymentDetails,
            paymentScheme,
            setPaymentScheme,
            inputValues,
            setInputValues,
            paymentId,
            setPaymentId
        }}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePayment = () => {
    const context = useContext(PaymentContext);
    if (!context) {
        throw new Error('usePayment must be used within an PaymentProvider');
    }
    return context;
};