import { apiCall } from './api';

const removeMaskFormatting = (value) => {
    return value.replace(/-/g, '');
};

// Get payment information based on paymentId UUID
export const getPaymentDetails = async (paymentId) => {

    try {
        const response = await apiCall('get', null, 'payments/' + paymentId);

        const responseData = response.data.data;

        if (response.status === 200) {
            const payeeData = {
                success: true,
                amount: responseData.payment.amount,
                currency: responseData.payment.currency,
                payee: responseData.payee.name,
                reference: responseData.payment.reference,
                status: responseData.status,
                paymentScheme: responseData.payment.paymentMethod ?? ''
            };

            const getAccountDetailValue = (accountDetails, type) => {
                const detail = accountDetails.find(item => item.type === type);
                return detail ? detail.value : '';
            };

            const accountDetails = responseData.payer.accountDetails;
            let sortCode = '';
            let accountNo = '';
            let bic = '';
            let iban = '';
            if (accountDetails) {
                sortCode = getAccountDetailValue(accountDetails, 'SORT_CODE');
                accountNo = getAccountDetailValue(accountDetails, 'ACCOUNT_NUMBER');
                bic = getAccountDetailValue(accountDetails, 'BIC');
                iban = getAccountDetailValue(accountDetails, 'IBAN');
            }

            const country = responseData.payer.address.country;

            const payerData = {
                accountName: responseData.payer.name,
                sortCode: removeMaskFormatting(sortCode),
                accountNo: accountNo,
                bic: bic,
                iban: iban,
                country: country
            }

            return { payeeData, payerData };
        } else {
            const payeeData = {
                success: false,
                amount: 0,
                payee: `${response.status}`,
                reference: `${response.data.error.message}`,
                status: ''
            };

            return { payeeData, payerData: null };
        }

    } catch (error) {
        const data = {
            success: false,
            amount: 0,
            payee: `500`,
            reference: 'Internal Server Error',
            status: ''
        };

        return { payeeData: data, payerData: null };
    }
}

// Get auth URLs for payment
export const getPaymentAuth = async (paymentId, accountData, institution) => {
    try {

        const data = {
            paymentMethod: 'OB',
            name: accountData.accountName,
            country: accountData.country,
            payerAccount: [
                {
                    type: 'SORT_CODE',
                    value: accountData.sortCode
                },
                {
                    type: 'ACCOUNT_NUMBER',
                    value: accountData.accountNo
                }
            ],
            institutionId: institution.institutionId
        };

        const response = await apiCall('post', data, 'payments/' + paymentId + '/auth');

        if (response.status === 200) {
            return response.data;    
        } else {
            return response.data;
        }

    } catch (error) {
        return { error: { message: error.message} };
    }
}

export const getPaymentStatus = async (paymentId) => {
    try {
        const response = await apiCall('get', null, 'payments/' + paymentId + '/status');

        return response.data;

    } catch (error) {
        return { error: error.message };
    }
}

// export const resetPaymentStatus = async (paymentId) => {
//     try {
//         const response = await apiCall('get', null, 'payments/' + paymentId + '/reset');
//
//         return response.data;
//
//     } catch (error) {
//         throw new Error(error.message);
//     }
// }