import React, { useState } from 'react';
import '../../index.css';
import OAuthLoginButtons from "../OAuth/OAuthLoginButtons";
import { GoogleOAuthProvider } from '@react-oauth/google';

const BACSAuthScreen = (props) => {

  return (
      <div className='payment-screen h-full flex flex-col pb-6'>
          <h3>Authentication</h3>
          <div className="flex flex-col justify-start items-center my-12">
              <GoogleOAuthProvider clientId="your-client-id">
                  <OAuthLoginButtons
                      setCurrentScreen={props.setCurrentScreen}
                  />
              </GoogleOAuthProvider>
          </div>
          <h3 className='mt-auto'>Information</h3>
          <p>By signing in to your account we can authenticate you with your company/organisation.
              Once we have confirmed your identity, you will need to confirm your bank account
              details before proceeding to payment authorisation.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum turpis.
              Sed vehicula, arcu at tincidunt bibendum, dolor arcu vehicula augue, in malesuada
              erat velit non nibh. Maecenas dolor felis, vehicula at quam sed, imperdiet euismod orci.
              Fusce id massa dapibus, facilisis lorem sit amet, congue turpis. Ut lacinia nulla lorem.
              Curabitur nec lacus id velit dictum faucibus vitae a lorem. Nam tempor est libero, quis
              aliquet purus placerat vel. Mauris fermentum nunc ut lectus posuere vulputate. Praesent
              dolor ligula, tempus a luctus id, ultricies eget nulla.
            </p><p>
              Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Morbi sollicitudin purus purus, a vehicula turpis dapibus et. In tincidunt ligula sit
              amet convallis ultricies. Nam accumsan efficitur mauris id fringilla. Morbi tincidunt
              id orci vitae eleifend. Integer finibus ipsum sit amet efficitur posuere. Aliquam porta
              sodales urna. Nullam quis feugiat turpis, vel imperdiet nisi. Orci varius natoque penatibus
              et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum bibendum malesuada elit,
              quis sollicitudin lacus cursus quis. Class aptent taciti sociosqu ad litora torquent per
              conubia nostra, per inceptos himenaeos. Donec bibendum diam vitae ante maximus pharetra.
              Donec pellentesque dui luctus suscipit egestas. Donec condimentum semper nulla id egestas.
          </p>
      </div>
  );
};

export default BACSAuthScreen;