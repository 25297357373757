import React, {useEffect, useState} from 'react';
import '../../index.css'; // Import a separate CSS file for styling
import check from '../../images/check.svg';
import fail from '../../images/fail.svg';
import warning from '../../images/warning.svg';
import {validateIBAN, validateSortAccount} from '../../services/validateBankAccount';
import {useAuth} from "../../context/authContext";
import ValidateButton from "../buttons/ValidateButton";
import InputMask from 'react-input-mask';
import InstitutionComboBox from "./InstitutionComboBox";
import {getInstitutions} from "../../services/institutions";
import {usePayment} from "../../context/paymentContext";
import CircularProgress from '@mui/material/CircularProgress';
import {toast} from "react-toastify";

const OBAccountScreen = (props) => {

  const {inputValues, setInputValues} = usePayment();
  const [validationErrors, setValidationErrors] = [props.validationErrors, props.setValidationErrors];
  const [institution, setInstitution] = [props.institution, props.setInstitution];
  const [institutionOptions, setInstitutionOptions] = useState([institution]);

  const { setAuthenticated } = useAuth();

  async function getInstitutionList() {
    try {
      const institutions = await getInstitutions(inputValues.country);
      setInstitutionOptions(institutions);
    } catch (error) {
      toast.error('Failed to retrieve institutions', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  useEffect(() => {
      setAuthenticated(true);
      checkFields();
      validateAccount();
      getInstitutionList();
    }, []);

  function checkFields() {

    let errors = validationErrors;

    if (inputValues.accountName.trim() !== '') {
        errors.accountName = 'OK';
    }
    if (inputValues.country === 'GB') {
        if (inputValues.sortCode.trim() !== '') {
            errors.sortCode = '';
        }
        if (inputValues.accountNo.trim() !== '') {
            errors.accountNo = '';
        }
    } else {
        if (inputValues.bic.trim() !== '') {
            errors.bic = '';
        }
        if (inputValues.iban.trim() !== '') {
            errors.iban = '';
        }
    }

    setValidationErrors(errors);
  }

  // Validation function for a single field
  const validateField = (fieldName, value) => {
    // Your validation logic here
    // For example, checking if the field is not empty
    if (value.trim() === '') {
      return `Missing`;
    }

    if(fieldName === 'accountName' && value.trim() !== '') {
      // If the field is valid, return an empty string
      return 'OK';
    }
  }

  const removeMaskFormatting = (value) => {
    return value.replace(/-/g, '');
  };

  // Function to handle changes in input values
  const handleInputChange = async (fieldName, value) => {

    const newValue = removeMaskFormatting(value);

    // Update the input values state
    setInputValues({
      ...inputValues,
      [fieldName]: newValue,
    });

    // Validate the field and update the validationErrors state
    const error = validateField(fieldName, newValue);
    setValidationErrors({
      ...validationErrors,
      [fieldName]: error,
    });

    // await validateAccount();
  };

  const getStatusIcon = (fieldName) => {
    if (validationErrors[fieldName] === 'Missing') {
      return warning;
    } else if (validationErrors[fieldName] === 'Invalid') {
      return fail;
    } else if (validationErrors[fieldName] === 'OK') {
      return check;
    } else {
      return warning;
    }
  }

  const validateAccount = async () => {

    try {

      if (inputValues.sortCode === '' && inputValues.accountNo === '' && inputValues.bic === '' && inputValues.iban === '') {
        setValidationErrors({
          ...validationErrors,
          sortCode: 'Missing',
          accountNo: 'Missing',
          bic: 'Missing',
          iban: 'Missing'
        });
        return;
      }

      if (inputValues.country === 'GB') {
        const response = await validateSortAccount(inputValues.sortCode, inputValues.accountNo);

        if (response.success) {
          setValidationErrors({
            ...validationErrors,
            sortCode: 'OK',
            accountNo: 'OK'
          });

        } else if (!response.success) {
          setValidationErrors({
            ...validationErrors,
            sortCode: 'Invalid',
            accountNo: 'Invalid'
          });
        }
      } else {
        const response = await validateIBAN(inputValues.bic, inputValues.iban);

        if (response.success) {
          setValidationErrors({
            ...validationErrors,
            bic: 'OK',
            iban: 'OK'
          });

        } else {
          setValidationErrors({
            ...validationErrors,
            bic: 'Invalid',
            iban: 'Invalid'
          });
        }
      }

    } catch (error) {
      toast.error('Validation Request Failed', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
      <div className='payment-screen'>
        { props.loading ?
            <div className='flex flex-col justify-center items-center pt-16'>
              <p className='mb-8'>Requesting authorisation links...</p>
              <CircularProgress />
            </div>
        :
        <>
          <h3>Your Bank Account</h3>
          <form className='mt-6'>

            <div className='input-field-div'>
              <h5 className='mb-0'>Country</h5>
              <input
                  disabled
                  type="text"
                  value={inputValues.country}
                  onChange={(e) => handleInputChange('country', e.target.value)}
              />
            </div>

            <div className='input-field-div'>
              <h5 className='mb-0'>Account Name</h5>
              <input
                  type="text"
                  value={inputValues.accountName}
                  onChange={(e) => handleInputChange('accountName', e.target.value)}
              />
              <div className='flex flex-row'><img src={getStatusIcon('accountName')} className='mr-2'
                                                  alt='Status icon'/>
                <p>{validationErrors.accountName}</p></div>
            </div>

            {inputValues.country === 'GB' ?
                <>
                  <div className='input-field-div'>
                    <h5 className='mb-0'>Sort Code</h5>
                    <InputMask
                        mask="99-99-99"
                        maskChar={null}
                        type="text"
                        value={inputValues.sortCode}
                        onChange={(e) => handleInputChange('sortCode', e.target.value)}
                    />
                    <div className='flex flex-row'><img src={getStatusIcon('sortCode')} className='mr-2'
                                                        alt='Status icon'/>
                      <p>{validationErrors.sortCode}</p></div>
                  </div>

                  <div className='input-field-div'>
                    <h5 className='mb-0'>Account No</h5>
                    <input
                        type="text"
                        value={inputValues.accountNo}
                        onChange={(e) => handleInputChange('accountNo', e.target.value)}
                    />
                    <div className='flex flex-row'><img src={getStatusIcon('accountNo')} className='mr-2'
                                                        alt='Status icon'/>
                      <p>{validationErrors.accountNo}</p></div>
                  </div>
                </>
                :
                <>
                  <div className='input-field-div'>
                    <h5 className='mb-0'>BIC / SWIFT</h5>
                    <input
                        type="text"
                        value={inputValues.bic}
                        onChange={(e) => handleInputChange('bic', e.target.value)}
                    />
                    <div className='flex flex-row'><img src={getStatusIcon('bic')} className='mr-2' alt='Status icon'/>
                      <p>{validationErrors.bic}</p></div>
                  </div>

                  <div className='input-field-div'>
                    <h5 className='mb-0'>IBAN</h5>
                    <input
                        type="text"
                        value={inputValues.iban}
                        onChange={(e) => handleInputChange('iban', e.target.value)}
                    />
                    <div className='flex flex-row'><img src={getStatusIcon('iban')} className='mr-2' alt='Status icon'/>
                      <p>{validationErrors.iban}</p></div>
                  </div>
                </>
            }

            <div className='input-field-div'>
              <h5 className='mb-0'>Institution</h5>
              <InstitutionComboBox
                  setInstitution={setInstitution}
                  institutions={institutionOptions}
                  institution={institution}
              />
            </div>

          </form>

          <div className='flex flex-row items-center space-between mt-8'>
            <div className='relative mr-auto'>
              <ValidateButton onPress={validateAccount}/>
            </div>
            {institution.logoUrl !== '' ?
                <img className='yvr-institution-logo' src={institution.logoUrl} alt='Bank Logo'/> : null}
          </div>
        </>
        }
      </div>
  );
};

export default OBAccountScreen;