import React, {useEffect, useState} from 'react';
import '../../index.css'; // Import a separate CSS file for styling
import OBAccountScreen from './OBAccountScreen';
import OBAuthScreen from './OBAuthScreen';
import OBSuccessScreen from './OBSuccessScreen';
import {getPaymentAuth, getPaymentStatus} from '../../services/payments';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {usePayment} from "../../context/paymentContext";

const OBAuth = () => {

    const { inputValues, setPaymentScheme, paymentId, paymentDetails, setPaymentDetails } = usePayment();

    const [loading, setLoading] = useState(false);

    const [requestId, setRequestId] = useState('');

    const [institution, setInstitution] = useState({
        institutionId: '',
        name: '',
        logoUrl: ''
    });

    // State to hold validation errors
    const [validationErrors, setValidationErrors] = useState({
        accountName: 'Missing',
        accountNo: 'Missing',
        sortCode: 'Missing',
        bic: 'Missing',
        iban: 'Missing',
        country: 'Missing',
    });

    const [authLinks, setAuthLinks] = useState({
        authUrl: null,
        qrCodeUrl: null
    });

    const validStatuses = ['AWAITING_AUTHORISATION']

    useEffect(() => {
        if (!validStatuses.includes(paymentDetails.status)) {
            setCurrentScreen(2);
        }
    }, []);

    const [currentScreen, setCurrentScreen] = useState(0);
    const totalScreens = 3; // Set the total number of screens

    const handleAuthorise = async () => {

        let missing = false;
        if (
            inputValues.accountName === null || inputValues.accountName === '' ||
            ( inputValues.country === 'GB' && (
            inputValues.sortCode === null || inputValues.sortCode === ''
            || inputValues.accountNo === null || inputValues.accountNo === '')) ||
            ( inputValues.country !== 'GB' && (
            inputValues.bic === null || inputValues.bic === ''
            || inputValues.iban === null || inputValues.iban === '')) ||
            institution.institutionId === null || institution.institutionId === ''
        ) { missing = true; }

        if (missing) {
            toast.error('Information Missing', {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: 'missing-info'
            });

            return;
        }

        setLoading(true);

        const response = await getPaymentAuth(paymentId, inputValues, institution);
        if (response.meta.requestId) setRequestId(response.meta.requestId);

        if (!response.error) {
            const authLinks = {
                authUrl: response.data.authUrl,
                qrCodeUrl: response.data.qrCodeUrl
            };

            setAuthLinks(authLinks);

            setLoading(false);

            setCurrentScreen((prevScreen) => (prevScreen + 1) % totalScreens);
        } else {
            toast.error(
                <div>Error: {response.error.message}<br /> Request Id: {response.meta.requestId}</div>,
        {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'auth-request-failed'
                });

            setLoading(false);
        }
    };

    const handleCheck = async () => {

        const paymentStatusBody = await getPaymentStatus(paymentId);

        if (paymentStatusBody.meta.requestId && (paymentStatusBody.meta.requestId !== requestId)) {
            setRequestId(paymentStatusBody.meta.requestId);
        }

        if (paymentStatusBody.data.status) {
            if (paymentStatusBody.data.status !== paymentDetails.status) {
                setPaymentDetails((prevState) => ({
                    ...prevState,
                    status: paymentStatusBody.data.status
                }));
            }
        } else {
            toast.error('Payment Status Request Failed', {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: 'payment-status-failed'
            });
        }

        const validStatuses = ['AUTHORISED', 'FAILED', 'EXPIRED'];

        if (validStatuses.includes(paymentStatusBody.data.status) || validStatuses.includes(paymentDetails.status)) {
            setCurrentScreen((prevScreen) => (prevScreen + 1) % totalScreens);
        }

    };

    const handleBack = () => {
        if (paymentDetails.status !== 'AUTHORISED') {
            setCurrentScreen((prevScreen) => (prevScreen - 1 + totalScreens) % totalScreens);
        }
    }

    return (

        <div className="yvr-box payment-auth">
            <ToastContainer
                position={toast.POSITION.BOTTOM_RIGHT}
                autoClose={false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
            />

            {currentScreen === 0 && <OBAccountScreen
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                institution={institution}
                setInstitution={setInstitution}
                loading={loading}
            />}
            {currentScreen === 1 && <OBAuthScreen
                institution={institution}
                authLinks={authLinks}
                setAuthLinks={setAuthLinks}
                loading={loading}
                handleCheck={handleCheck}
            />}
            {currentScreen === 2 && <OBSuccessScreen
                requestId={requestId}
            />}

            <div className='button-group'>

                { currentScreen === 0 &&
                    <>
                        <button className='back-button opacity-0' onClick={() => setPaymentScheme('')} disabled>
                            Back
                        </button>
                        <button className='next-button' onClick={handleAuthorise}>
                            Authorise
                        </button>
                    </>
                }

                {currentScreen === 1 &&
                    <>
                        <button className='back-button' onClick={handleBack}>
                            Back
                        </button>
                        {/*<button className='next-button' onClick={handleCheck}>*/}
                        {/*    Check Payment*/}
                        {/*</button>*/}
                    </>
                }
                { currentScreen === 2 &&
                    <>
                        <button disabled className='opacity-0'></button>
                    </>
                }
            </div>

        </div>
    );
};

export default OBAuth;