import axios from "axios";

const url = process.env.REACT_APP_JARVIS_URL;
const authToken = process.env.REACT_APP_JARVIS_TOKEN;

export const apiCall = async (method, body, path) => {

    const headers = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
    };

    const config = {
      method: method,
      url: `${url}/${path}`,
      headers: {
        ...headers,
      },
      data: body,
    };
  
    try {
        return await axios(config);
    } catch (error) {
        return error.response;
    }
    
}