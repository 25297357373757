import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { AuthProvider } from './context/authContext';
import NotFoundPage from "./pages/NotFoundPage";
import AuthSuccessPage from "./pages/AuthSuccessPage";
import PaymentNotFoundPage from "./pages/PaymentNotFoundPage";
import {PaymentProvider} from "./context/paymentContext";

function App() {
  return (
      <AuthProvider>
          <PaymentProvider>
              <Routes>
                <Route path="/" element={<HomePage />}/>
                  <Route path="/success" element={<AuthSuccessPage />}/>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/payment-not-found" element={<PaymentNotFoundPage />} />
              </Routes>
          </PaymentProvider>
      </AuthProvider>
  );
}

export default App;
