import React from 'react';
import '../../index.css'; // Import a separate CSS file for styling
import check from '../../images/check.svg';

const BACSSuccessScreen = () => {
  return (
    <div className='payment-screen h-full'>
        <div className='flex flex-col items-center justify-center h-full max-w-sm text-center ml-auto mr-auto'>
          <img src={check} alt="" className='w-12 mb-5' />
          <h2>Payment has been successfully authorised</h2>
          <p>This page will automatically close and you will be redirected to your application.</p>
        </div>
    </div>
  );
};

export default BACSSuccessScreen;