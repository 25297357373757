import React, {useEffect} from 'react';
import '../../index.css';
import check from '../../images/check.svg';
import fail from '../../images/fail.svg';
import warning from '../../images/warning.svg';
import {usePayment} from "../../context/paymentContext";
import {Link} from "react-router-dom";

const OBSuccessScreen = ({requestId}) => {

    const { paymentDetails } = usePayment();

    useEffect(() => {
        if (paymentDetails.status === 'AUTHORISED') {
            setTimeout(() => {
                window.close();
            }, 5000);
        }
    }, []);

    const renderScreen = () => {
        if (paymentDetails.status === 'FAILED') {
            return (
                <>
                    <img src={fail} alt="" className='w-12 mb-5'/>
                    <h2>Payment authorisation has failed</h2>
                    <p>Please try again or contact Yavrio for assistance</p>
                    <p><b>Request Id:</b> {requestId}</p>
                    <p><Link to='mailto:mailto:support@yavr.io' className='text-yavrio-blue underline'>Contact Yavrio
                        support</Link></p>
                </>
            );
        } else if (paymentDetails.status === 'EXPIRED') {
            return (
                <>
                    <img src={warning} alt="" className='w-12 mb-5'/>
                    <h2>Payment link has expired.</h2>
                    <p>Please close this window and restart the payment process.</p>
                    <p><b>Request Id:</b> {requestId}</p>
                    <p><Link to='mailto:support@yavr.io' className='text-yavrio-blue underline'>Contact Yavrio
                        support</Link></p>
                </>
            );
        } else if (paymentDetails.status === 'AUTHORISED' ||
                    paymentDetails.status === 'COMPLETED' ||
                    paymentDetails.status === 'PENDING') {
            return (
                <>
                    <img src={check} alt="" className='w-12 mb-5'/>
                    <h2>Payment has been successfully authorised</h2>
                    <p>This page will automatically close and you will be redirected to your application.</p>
                </>
            );
        } else {
            return (
                <>
                    <img src={warning} alt="" className='w-12 mb-5'/>
                    <h2>Payment status unknown</h2>
                    <p>Please contact Yavrio for assistance</p>
                    <p><b>Request Id:</b> {requestId}</p>
                    <p><Link to='mailto:support@yavr.io' className='text-yavrio-blue underline'>Contact Yavrio support</Link></p>
                </>
            );
        }
    }

  return (
    <div className='payment-screen h-full'>
        <div className='flex flex-col items-center justify-center h-full max-w-sm text-center ml-auto mr-auto'>
            {renderScreen()}
        </div>
    </div>
  );
};

export default OBSuccessScreen;