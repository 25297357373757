import {apiCall} from './api';

export const validateSortAccount = async (sortCode, accountNo) => {
    const type = 'SORTCODE';
    const body = {
        type: type,
        sortCode: sortCode,
        accountNo: accountNo
    };

    try {
        const response = await apiCall('post', body, 'validate');
        const success = response.data.data.valid;

        return { success };
    } catch (error) {
        throw new Error('Validation process failed');
    }
}

export const validateIBAN = async (bic, iban) => {
    const type = 'IBAN';
    const body = {
        type: type,
        iban: iban,
        bic: bic
    };

    try {
        const response = await apiCall('post', body, 'validate');

        const success = response.data.data.valid;

        return { success };
    } catch (error) {
        throw new Error('Validation process failed');
    }
}