import React from 'react';
import '../index.css';
import "react-toastify/dist/ReactToastify.css";
import OBAuth from "./OB/OBAuth";
import BACSAuth from "./BACS/BACSAuth";
import {usePayment} from "../context/paymentContext";

const PaymentAuth = () => {

  const { paymentScheme } = usePayment();

  return (
    <>
      { paymentScheme === 'OB' &&
        <OBAuth />
      }

      { paymentScheme === 'BACS' &&
        <BACSAuth />
      }
    </>
  );
};

export default PaymentAuth;