import React, { useEffect } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import '../index.css'; // Import a separate CSS file for styling
import PaymentDetails from '../components/PaymentDetails';
import PaymentAuth from '../components/PaymentAuth';
import Icon from '../images/YavrioIcon';
import { getPaymentDetails } from '../services/payments';
import Header from "../components/Header";
import SelectPaymentType from "../components/SelectPaymentType";
import NotFoundPage from "./NotFoundPage";
import {usePayment} from "../context/paymentContext";

const HomePage = () => {

  const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(true);

  const { setPaymentDetails, paymentScheme, setPaymentScheme, setInputValues, setPaymentId } = usePayment();

    async function getPayment() {

        const { payeeData, payerData } = await getPaymentDetails(searchParams.get('paymentId'));

        if (payeeData.success === false) {
            navigate('/payment-not-found');
        } else {

            if (payeeData.paymentScheme === 'FPS' || payeeData.paymentScheme === 'SEPA') {
                setPaymentScheme('OB');
            } else {
                setPaymentScheme(payeeData.paymentScheme);
            }

            setPaymentDetails(payeeData);
            setInputValues(prevState => ({
                ...prevState,
                accountName: payerData.accountName,
                accountNo: payerData.accountNo,
                sortCode: payerData.sortCode,
                bic: payerData.bic,
                iban: payerData.iban,
                country: payerData.country,
            }));

            setPaymentId(searchParams.get('paymentId'));
        }

        setLoading(false);
    }

  useEffect(() => {
    getPayment().then();
  }, []);

  return (
      <>
        { (searchParams.get('paymentId') === null) ? <NotFoundPage/> :
        <div className="App">

          <header className="App-header">
            <Header/>
          </header>

          <div className='page-wrapper'>

            { loading ?
                <></>
            :
                <>
                { (paymentScheme === 'OB') ?
                    <div className='payment-wrapper'>
                      <PaymentAuth />
                      <PaymentDetails />
                    </div>
                    :
                    <SelectPaymentType />
                }
                </>
            }
            <Icon/>

          </div>

        </div>
        }
      </>

  );
};

export default HomePage;