import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import '../../index.css';

export default function InstitutionComboBox(props) {

    function setInstitution(newValue) {
        props.setInstitution(newValue);
    }

    return (
        <Autocomplete
            id=""
            sx={{ width: '100%' }}
            size="small"
            className="yvr-combobox"
            options={props.institutions}
            value={props.institution}
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(e, newValue) => {
                if (newValue) {
                    setInstitution(newValue);
                } else {
                    setInstitution({
                        institutionId: '',
                        name: '',
                        logoUrl: ''
                    });
                }
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize: '0.9rem' }} {...props}>
                    <img
                        loading="lazy"
                        width="30"
                        srcSet={option.logoUrl}
                        src={option.logoUrl}
                        alt=""
                    />
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select your institution"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}