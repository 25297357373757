import React from 'react';
import '../index.css';
import {Link} from "react-router-dom";
import YavrioIcon from "../images/YavrioIcon";

const NotFoundPage = () => {

    return (
        <div className="flex flex-col justify-center items-center h-screen w-full">
            <h1>404 page not found</h1>
            <p>Contact <Link to='mailto:support@yavr.io' className='text-yavrio-blue underline'>Yavrio
                support</Link> for assistance.</p>
            <YavrioIcon/>
        </div>
    );
};

export default NotFoundPage;