import React, { useEffect } from 'react';
import '../../index.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from "@mui/material/CircularProgress";

const OBAuthScreen = (props) => {

    const pollStatus = async () => {
        await props.handleCheck();
    };

    useEffect(() => {
        const interval = setInterval(pollStatus, 1000);
        return () => clearInterval(interval);
    }, []);

  // State Variables
  const authLinks = props.authLinks

  return (
    <div className='payment-screen'>
        { props.loading ?
            <div className='flex flex-col justify-center items-center pt-16'>
                <p className='mb-8'>Checking payment status...</p>
                <CircularProgress />
            </div>
            :
            <>
                <h3>Authorise Payment</h3>
                <div className='qr-wrapper ml-auto mr-auto mt-6'>
                    <img src={props.institution.logoUrl} alt='Bank Logo' className='h-auto w-auto max-h-48'/>
                    <img src={authLinks.qrCodeUrl} alt='Auth QR code' className='h-auto w-auto max-h-48'/>
                </div>
                <p className='text-center mt-6'>
                    <span className='bg-yavrio-blue hover:bg-gray-700 py-2 rounded-md text-white mr-1'>
                    <a href={authLinks.authUrl}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    className='font-semibold py-2 px-3'>
                    Click here</a>
                    </span> or scan the QR code to authorise payment.</p>

                <h3 className='mt-16'>Information</h3>
                <p className='text-sm'>
                    In order to share your {props.institution.name} data with Yavrio, you will now be securely
                    redirected to your bank to confirm your consent for Yapily Connect to read the following
                    information:
                    <ul className='list-disc list-inside mt-2'>
                        <li>Identification details</li>
                        <li>Account(s) details</li>
                        <li>Balances</li>
                        <li>Transaction Statements from the past 12 months</li>
                        <li>Credit Card(s) Statements from the past 12 months</li>
                    </ul>
                </p>

                <div className='mt-8'>
                    <Accordion square sx={{boxShadow: 'none'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h4>Data Sharing</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                Yapily Connect will retrieve your bank data based on your request and
                                provide this information to Yavrio.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square sx={{boxShadow: 'none'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <h4>Secure Connection</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                Data is securely retrieved in read-only format and only for the
                                duration agreed with you. You have the right to withdraw your consent at any time.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square sx={{boxShadow: 'none'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <h4>FCA Authorisation</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                Yavrio is an agent of Yapily Connect Ltd. Yapily Connect Ltd is authorised and
                                regulated by the Financial Conduct Authority under the Payment Service Regulations
                                2017 [827001] for the provision of Account Information and Payment Initiation services.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square sx={{boxShadow: 'none'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel4-content"
                            id="panel4-header"
                        >
                            <h4>About the access</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                Yapily Connect will then use these details with Yavrio solely for the purposes of
                                receiving Bank transactions and validating Vendor Bank information, you can cancel
                                consent at any time via the "Remove Consent" action on the ribbon of the Bank Account
                                Card or via your bank.

                                <p className='mt-2'>Please read <a className='underline decoration-solid text-blue-800'
                                                                   href='https://yapi.ly/MwMo'>Yapily Connect AIS -
                                    Terms & Conditions</a></p>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>
            }
            </div>
            );
        };

export default OBAuthScreen;