import React, { useState } from 'react';
import '../../index.css'; // Import a separate CSS file for styling
import check from '../../images/check.svg';
import fail from '../../images/fail.svg';
import warning from '../../images/warning.svg';
import InputMask from 'react-input-mask';
import ValidateButton from "../buttons/ValidateButton";
import {usePayment} from "../../context/paymentContext";

const BACSAccountScreen = (props) => {

  const {inputValues, setInputValues} = usePayment();
  const [validationErrors, setValidationErrors] = [props.validationErrors, props.setValidationErrors];
  const [validationMessage, setValidationMessage] = useState('');

  // Validation function for a single field
  const validateField = (fieldName, value) => {
    // Your validation logic here
    // For example, checking if the field is not empty
    if (value.trim() === '') {
      return `Missing`;
    }

    // If the field is valid, return an empty string
    return '';
  };

  // Function to handle changes in input values
  const handleInputChange = (fieldName, value) => {

    // Update the input values state
    setInputValues({
      ...inputValues,
      [fieldName]: value,
    });

    // Validate the field and update the validationErrors state
    const error = validateField(fieldName, value);
    setValidationErrors({
      ...validationErrors,
      [fieldName]: error,
    });

  };

  const getStatusIcon = (fieldName) => {
    if (validationErrors[fieldName] === 'Missing') {
      return warning;
    } else if (validationErrors[fieldName] === 'Invalid') {
      return fail;
    } else if (validationErrors[fieldName] === 'OK') {
      return check;
    } else {
      return warning;
    }
  }

  const validateAccount = async () => {

    try {
        //
    } catch (error) {
      setValidationMessage(error.message);
    }
  };

  return (
    <div className='payment-screen'>
        <h3>Your Bank Account</h3>
        <form className='mt-6'>

          <div className='input-field-div'>
            <h5 className='mb-0'>Account Name</h5>
            <input
              type="text"
              value={inputValues.accountName}
              onChange={(e) => handleInputChange('accountName', e.target.value)}
            />
            <div className='flex flex-row'><img src={getStatusIcon('accountName')} className='mr-2' alt='Status icon'/><p>{validationErrors.accountName}</p></div>
          </div>

          <div className='input-field-div'>
            <h5 className='mb-0'>Sort Code</h5>
            <InputMask
              mask="99-99-99"
              maskChar={null}
              type="text"
              value={inputValues.sortCode}
              onChange={(e) => handleInputChange('sortCode', e.target.value)}
            />
            <div className='flex flex-row'><img src={getStatusIcon('sortCode')}className='mr-2' alt='Status icon'/><p>{validationErrors.sortCode}</p></div>
          </div>

          <div className='input-field-div'>
            <h5 className='mb-0'>Account No</h5>
            <input
              type="text"
              value={inputValues.accountNo}
              onChange={(e) => handleInputChange('accountNo', e.target.value)}
            />
            <div className='flex flex-row'><img src={getStatusIcon('accountNo')} className='mr-2' alt='Status icon'/><p>{validationErrors.accountNo}</p></div>
          </div>

        </form>

        <div className='flex flex-row items-center space-between mt-12'>
          <div className='relative mr-auto'>
            <ValidateButton onClick={validateAccount}/>
            <p className='absolute bottom-100 text-red-500 text-xs'>{validationMessage}</p>
          </div>
        </div>
    </div>
  );
};

export default BACSAccountScreen;