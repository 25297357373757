import '../index.css';
import Logo from "../images/YavrioLogo";
import React from "react";
import {useAuth} from "../context/authContext";

const Header = () => {

    const { user } = useAuth();

    return (
        <div className="yvr-header flex flex-row justify-between items-center">
            <Logo />
            <div className="flex flex-row items-center">
                <span className="mr-10 text-gray-400 text-sm">{user}</span>
                <h3 className="mb-0">Payments Web Checkout</h3>
            </div>
        </div>
    );
};

export default Header;