import { RefreshCcw } from "react-feather";
import React from "react";

const ValidateButton = ({ onPress }) => {

    function rotateIcon() {
        // rotate the refresh icon 360 degrees
        let refreshIcon = document.querySelector('.rotate');
        refreshIcon.classList.toggle('rotate-on');

        // reset the icon after 1 second
        setTimeout(() => {
            refreshIcon.classList.toggle('rotate-on');
        }, 0);
    }

    return (
        <button onClick={() => {
            rotateIcon();
            onPress();
        }}
                className='button px-4 flex flex-row items-center
                              border border-yavrio-blue
                              text-yavrio-blue font-normal
                              hover:bg-yavrio-blue hover:text-white'>
            Validate
            <RefreshCcw className='rotate ml-2 w-4'/>
        </button>
    );
};

export default ValidateButton;