import * as Msal from 'msal';

export const msalConfig = {
    auth: {
        clientId: "caa12d4a-d6b7-4a44-b44d-3801cf07712a",
        authority: 'https://login.microsoftonline.com/yavrio.onmicrosoft.com',
        redirectUri: 'https://checkout.yavr.io/success',
    },
    cache: {
        cacheLocation: "sessionStorage", // "sessionStorage" or"localStorage"
        storeAuthStateInCookie: true,
    },
};

export const loginRequest = {
    scopes: ['User.Read'],
};

export const msalInstance = new Msal.UserAgentApplication(msalConfig);