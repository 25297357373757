const {apiCall} = require("./api");

async function getInstitutions(country) {

    try {
        const yvrInstitutions = await apiCall('get', null, 'institutions?country=' + country);

        return yvrInstitutions.data.data.institutions.map(inst => {
            return {
                institutionId: inst.id,
                name: inst.name,
                logoUrl: inst.media.iconUrl
            }
        });
    } catch (error) {
        throw error;
    }

}

module.exports = { getInstitutions };

